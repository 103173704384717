import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer/footer';
import BackButton from '../components/helpBackButton/helpBackButton';
import BootstrapNavbar from '../components/navgationBar/navigationBar';
import * as Styled from '../styles';
import GlobalStyles from '../styles/globalStyles';

export default function Returns() {
  return (
    <>
      <Helmet>
        <title>Pro2Go's Return Policy | Pro2Go</title>
        <meta
          name="description"
          content="Pro2Go's Return Policy - no refunds, exchange only for propane cylinders"
        ></meta>
        <meta
          name="keywords"
          content="bbq cylinders,propane cylinders,cylinder exchange service,london ontario,canada"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
      </Helmet>
      <GlobalStyles />
      <BootstrapNavbar />
      <Styled.MainContainer>
        <Styled.Header>PRO2GO’S RETURNS POLICY</Styled.Header>
        <Styled.SubHeader>No Refunds, Exchange Only.</Styled.SubHeader>

        <Styled.ParaText>
          If you have purchased a defective cylinder, you may exchange it by
          calling 1-833-820-6080 and one of our customer service representatives
          will gladly assist you in completing your exchange.
        </Styled.ParaText>
        <Styled.ParaText>
          We will not refund cylinders that have been damaged in any way, nor
          will we by any means pick up a defective cylinder. It is the
          customer’s responsibility upon purchase to return the defective
          cylinder to the kiosk where it was purchased in order to complete an
          exchange.
        </Styled.ParaText>
        <Styled.ParaText>
          If for any reason the kiosk in which you conducted your initial
          transaction is unable to process the exchange, PRO2GO will advise of
          the nearest location with operational kiosks and it will be the
          customer’s responsibility to get to that location and complete an
          exchange.
        </Styled.ParaText>

        <Styled.SubParaText>
          If you have any issues concerning your exchange or purchase, please
          call 1-833-820-6080.
        </Styled.SubParaText>
        <BackButton />
      </Styled.MainContainer>
      <Footer />
    </>
  );
}
